<template>
    <div class="addCustomer">
        <van-form @submit="onSubmit">
            <van-cell-group>
            <fragment v-for="(item,index) in formData" :key="index">
                <van-field
                    v-if="item.type === 'input'"
                    v-model="item.value"
                    :name="item.name"
                    :label="item.label"
                    :placeholder="item.placeholder"
                    :rules="item.rules"
                    :required='item.required'
                />
                <van-field :name="item.name" :label="item.label" v-if="item.type === 'radio'">
                    <template #input>
                        <van-radio-group v-model="item.value" direction="horizontal">
                        <van-radio :name="1">男</van-radio>
                        <van-radio :name="2">女</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>
                <van-field
                    v-if="item.type === 'studio'"
                    readonly
                    clickable
                    :name="item.name"
                    :value="item.value"
                    :label="item.label"
                    :rules="item.rules"
                    :required='item.required'
                    :placeholder="item.placeholder"
                    @click="showStudio = true;activeIndex = index"
                />
                <van-field
                    v-if="item.type === 'course'"
                    readonly
                    clickable
                    :name="item.name"
                    :value="item.value"
                    :label="item.label"
                    :rules="item.rules"
                    :required='item.required'
                    :placeholder="item.placeholder"
                    @click="showCourse = true;activeIndex = index"
                />
                <van-field
                    v-if="item.type === 'channel'"
                    readonly
                    clickable
                    :name="item.name"
                    :value="item.value"
                    :label="item.label"
                    :rules="item.rules"
                    :required='item.required'
                    :placeholder="item.placeholder"
                    @click="showChannel = true;activeIndex = index"
                />
                <van-field
                    v-if="item.type === 'time'"
                    readonly
                    clickable
                    :name="item.name"
                    :value="item.value"
                    :label="item.label"
                    :rules="item.rules"
                    :required='item.required'
                    :placeholder="item.placeholder"
                    @click="showTime = true;activeIndex = index"
                />
                <van-field
                    v-if="item.type === 'textarea'"
                    v-model="item.value"
                    rows="3"
                    autosize
                    :label="item.label"
                    :name="item.name"
                    type="textarea"
                    :rules="item.rules"
                    :required='item.required'
                    :placeholder="item.placeholder"
                />
            </fragment>
            </van-cell-group>

            <van-button type="primary" size="large" :loading="btnLoading" :disabled="btnLoading" native-type="submit">保存</van-button>
        </van-form>

        <van-popup v-model="showStudio" position="bottom" round :style="{ height: '80%' }">
            <van-picker
                show-toolbar
                value-key="filter_name"
                :columns="studios"
                @confirm="onConfirmStudio"
                @cancel="showStudio = false"
            />
        </van-popup>
        <van-popup v-model="showCourse" position="bottom" round :style="{ height: '80%' }">
            <van-picker
                show-toolbar
                value-key="filter_name"
                :columns="courses"
                @confirm="onConfirmCourse"
                @cancel="showCourse = false"
            />
        </van-popup>
        <van-popup v-model="showChannel" position="bottom" round :style="{ height: '80%' }">
            <van-tree-select
                height="165vw"
                :items="channels"
                :active-id.sync="channel_id"
                :main-active-index.sync="active_channel_id"
                @click-item='onConfirmChannel'
                @click-nav='onChannelNav'>
                <template v-if="active_list.length == 0" #content>
                    <van-empty description="暂无渠道" />
                </template>
            </van-tree-select>
        </van-popup>
        <van-popup v-model="showTime" position="bottom" round :style="{ height: '80%' }">
            <van-datetime-picker
                type="datetime"
                v-model="currentDate"
                :min-date="minDate"
                :max-date="maxDate"
                @confirm="onConfirmTime"
                @cancel="showTime = false"
            />
        </van-popup>
    </div>
</template>

<script>
const formData = [
  {
    name: 'name',
    label: '学员名称',
    type: 'input',
    value:'',
    required:true,
    placeholder:'请填写客户名称',
    rules: [{ required: true, message: '请输入客户名称!' }]
  },
  {
    name: 'cellphone',
    label: '联系电话',
    type: 'input',
    value:'',
    required:true,
    placeholder:'请填写联系电话',
    rules: [{ required: true, message: '请输入联系电话!' }],
  },
  {
    name: 'studio_id',
    label: '所属校区',
    type: 'studio',
    value:'',
    required:true,
    placeholder:'请选择所属校区',
    rules: [{ required: true, message: '请选择所属校区' }],
    items: {
      data: 'studios',
      label: 'filter_name',
      value: 'studio_id'
    }
  },
  {
    name: 'channel_id',
    label: '来源渠道',
    type: 'channel',
    value:'',
    required:true,
    placeholder:'请选择来源渠道',
    rules: [{ required: true, message: '请选择来源渠道!' }],
    items: {
      data: 'channels',
      label: 'filter_name',
      value: 'source_channel_id'
    }
  },
  {
    name: 'course_cate_id',
    label: '意向课程',
    type: 'course',
    value:'',
    required:false,
    placeholder:'请选择意向课程',
    rules: [],
    items: {
      data: 'classCategorys',
      label: 'filter_name',
      value: 'course_cate_id'
    }
  },
//   {
//     name: 'promise_date_time',
//     label: '邀约时间',
//     type: 'time',
//     value:'',
//     required:false,
//     placeholder:'请填写邀约时间',
//     rules: [],
//   },
//   {
//     name: 'course_consultant_id',
//     label: '课程顾问',
//     type: 'select',
//     value:'',
//     rules: [{ required: true, message: '请选择课程顾问!' }],
//     items: {
//       data: 'consultants',
//       label: 'filter_name',
//       value: 'course_consultant_id'
//     }
//   },
  {
    name: 'gender',
    label: '学员性别',
    type: 'radio',
    value:0,
    required:false,
    rules: [],
    items: {
      data: 'genderList',
      label: 'gender_name',
      value: 'gender_id'
    }
  },
  {
    name: 'year',
    label: '学员年龄',
    type: 'input',
    value:'',
    required:false,
    placeholder:'请填写学员年龄',
    rules: []
  },
//   {
//     name: 'birthday',
//     label: '出生日期',
//     type: 'date',
//     value:'',
//     required:false,
//     placeholder:'请填写出生日期',
//     rules: [],
//   },
  {
    name: 'school',
    label: '在读学校',
    type: 'input',
    value:'',
    required:false,
    placeholder:'请填写在读学校',
    rules: [],
  },
  {
    name: 'remark',
    label: '客户备注',
    type: 'textarea',
    value:'',
    required:false,
    placeholder:'请填写客户备注',
    rules: [],
  }
]

    import { Form,Field,RadioGroup,Radio,Picker,TreeSelect,DatetimePicker,Empty } from 'vant'
    import dayjs from 'dayjs'
    export default {
        name:'addCustomer',
        components: {
            [Form.name]:Form,
            [Field.name]:Field,
            [RadioGroup.name]:RadioGroup,
            [Radio.name]:Radio,
            [Picker.name]:Picker,
            [TreeSelect.name]:TreeSelect,
            [DatetimePicker.name]:DatetimePicker,
            [Empty.name]:Empty,
        },
        data() {
            return {
                formData,
                activeIndex:-1,
                studios:[],
                studio_id:'',
                showStudio:false,
                btnLoading:false,
                channels:[],
                channel_id:'',
                active_channel_id:'',
                showChannel:false,
                courses:[],
                course_cate_id:'',
                showCourse:false,
                promise_date_time:'',
                showTime:false,
                minDate: new Date(2020, 0, 1),
                maxDate: new Date(9999, 12, 30),
                currentDate: new Date(),
                active_list:[]
            }
        },
        created () {
            this.formData.forEach(item=>{
                item.value = ''
            })
            this.getStudio()
            this.getcourse()
            this.getChannel()
        },
        methods: {
            dayjs,
            onSubmit(value) {
                let obj = {...value}
                obj.studio_id = this.studio_id
                obj.channel_id = this.channel_id
                obj.course_cate_id = this.course_cate_id
                this.btnLoading = true
                this.$api.crm_customer_add(obj)
                .then(res=>{
                    this.$toast('操作成功~')
                    this.$router.go(-1)
                })
                .catch(err=>{
                    this.btnLoading = false
                    console.log(err)
                })
            },
            onConfirmStudio(value){
                this.studio_id = value.studio_id
                this.formData[this.activeIndex].value = value.filter_name
                this.showStudio = false
            },
            onConfirmCourse(value){
                this.course_cate_id = value.course_cate_id
                this.formData[this.activeIndex].value = value.filter_name
                this.showCourse = false
            },
            onConfirmChannel(value){
                this.formData[this.activeIndex].value = value.text
                this.channel_id = value.id
                this.showChannel = false
            },
            onChannelNav(val){
                this.active_channel_id = val
                this.active_list = this.channels[val].children
            },
            onConfirmTime(value){
                this.currentDate = value
                this.promise_date_time = dayjs(value).format("YYYY-MM-DD HH:mm")
                this.formData[this.activeIndex].value = dayjs(value).format("YYYY-MM-DD HH:mm")
                this.showTime = false
            },
            getStudio(){
                this.$api.crm_filter_stduio()
                .then(res=>{
                    this.studios = res.data
                })
            },
            getcourse(){
                this.$api.crm_filter_course()
                .then(res=>{
                    this.courses = res.data
                })
            },
            getChannel(){
                this.$api.crm_filter_channel()
                .then(res=>{
                    let channels = res.data
                    channels.forEach(item=>{
                        item.text = item.label
                        item.id = item.value
                        item.children.forEach(it=>{
                            it.text = it.label
                            it.id = it.value
                        })
                    })
                    this.channels = channels
                    this.active_list = channels[0].children
                })
            }
        },
    }
</script>

<style lang="less" scoped>
    .addCustomer{
        width: 93%;
        margin: 0 auto;
        margin-top: 15px;
        border-radius: 10px;
        overflow: hidden;
    }
</style>